<template>
  <div>
    <div class="fixed inset-0 darkblue">
      <div class="validate-wrapper">
        <div v-if="!dataLoaded">
          <div class="flex flex-col w-full items-center justify-center">
            <h1 class="text-center text-3xl text-white font-light">Проверяю email...</h1>
            <menu-spinner :local="true" class="mt-4"></menu-spinner>
          </div>
        </div>
        <div v-else>
          <div v-if="confirmed">
            <div class="flex flex-col w-full items-center justify-center">
              <font-awesome-icon icon="check"
                                 class="text-5xl text-white self-center"></font-awesome-icon>
              <h1 class="mt-3 text-center text-3xl text-white font-light">Email подтвержден!</h1>
              <button class="mt-12 px-3 py-2 bg-white rounded"
                      @click="toLogin">Перейти к логину</button>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col w-full items-center justify-center">
              <font-awesome-icon icon="times"
                                 class="text-5xl text-white self-center"></font-awesome-icon>
              <h1 class="mt-3 text-center text-3xl text-white font-light leading-tight">Ученик не найден</h1>
              <p class="text-center mt-6 text-white">Возможно, ссылка истекла или была введена с ошибкой?</p>
              <button class="mt-12 px-3 py-2 bg-white rounded"
                      @click="toRegister">Перейти к регистрации</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MenuSpinner from '@/components/MenuSpinner.vue';

export default {
  components: {
    MenuSpinner,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      dataLoaded: false,
      confirmed: false,
    };
  },
  created () {
    axios.get(`/api/v1/users/confirm/${this.hash}`)
        .then(() => {
          this.dataLoaded = true;
          this.confirmed = true;
        })
        .catch(() => {
          this.dataLoaded = true;
        });
  },
  methods: {
    toLogin () {
      this.$router.push({ name: 'PageLogin' });
    },
    toRegister () {
      this.$router.push({ name: 'PageRegister' });
    },
  },
};
</script>

<style scoped lang="scss">
.validate-wrapper {
  @apply flex flex-col items-center justify-center;
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 639px) {
    width: 80%;
  }

  @media (min-width: 640px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }

  @media (min-width: 1536px) {
    width: 18%;
  }
}

.darkblue {
  background: #111122;
}
</style>
